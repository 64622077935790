<template>
  <section class="all-cases-view" id="all-sendOuts-list">
    <div class="navbarComponent">
      <div class="container-default">
        <div class="listings-navbar">
          <el-row>
            <el-col :lg="7">
              <ul class="d-flex list-items-group-15 barcode-print">
                <li v-if="hasPermission('send_outs', 'create')">
                  <el-button
                    class="btn button-with-icon"
                    @click="onAddNewSendout"
                  >
                    <span class="mr-8">
                      <img
                        src="@/assets/images/icons/plus-icon.svg"
                        alt="icon"
                        class="inactive"
                      />
                      <img
                        src="@/assets/images/icons/plus-icon-white.svg"
                        alt="icon"
                        class="active"
                      />
                    </span>
                    <span>ADD NEW SEND OUT</span>
                  </el-button>
                </li>
                <li>
                  <div class="form-element input-with-icon search-input-lg">
                    <el-input
                      placeholder="SendoutId / Patient Name"
                      v-model="search_string"
                      @input="getAutoCompleteOrders()"
                      :clearable="true"
                    ></el-input>
                    <span>
                      <img
                        src="@/assets/images/icons/search-icon-with-bg-red.svg"
                        alt="icon"
                        class="img-fluid icon"
                      />
                    </span>
                  </div>
                </li>
              </ul>
            </el-col>
            <el-col :lg="17">
              <ul
                class="d-flex flex-wrap justify-content-end list-items-group-15"
              >
                <li>
                  <div
                    class="form-element search-with-filter-input-without-icon"
                  >
                    <el-select
                      v-model="searchBy"
                      clearable
                      filterable
                      default-first-option
                      :reserve-keyword="false"
                      placeholder="Select Search"
                      @change="clearInputSearch"
                      @clear="clearSearchBy"
                    >
                      <el-option
                        v-for="item in searchOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      />
                    </el-select>
                  </div>
                </li>

                <li v-if="searchBy == 'Patient DOB'">
                  <div class="search-with-date-input">
                    <el-date-picker
                      format="MM-DD-YYYY"
                      v-model="date_of_birth"
                      :disabled-date="disabledDate"
                      @change="fetchAllSendOuts()"
                      placeholder="Select DOB"
                      :clearable="true"
                    ></el-date-picker>
                  </div>
                </li>
                <li v-else-if="searchBy == 'hospital'">
                  <div class="form-element search-with-filter-input">
                    <el-select
                      v-model="hospital"
                      placeholder="Select"
                      filterable
                      clearable
                      default-first-option
                      @change="handleCurrentChange(1)"
                      @focus="fetchAllHospitals"
                      :loading="hospitalsLoading"
                    >
                      <el-option
                        v-for="hospital in hospitalsData.data"
                        :key="hospital"
                        :label="hospital.name"
                        :value="hospital._id"
                      ></el-option>
                    </el-select>
                    <span class="search-icon">
                      <img
                        src="@/assets/images/icons/search-icon-with-bg-red.svg"
                        alt="icon"
                        class="img-fluid"
                      />
                    </span>
                  </div>
                </li>
                <li v-else-if="searchBy == 'ordering_physician'">
                  <div class="form-element search-with-filter-input">
                    <el-select
                      v-model="physician"
                      placeholder="Select"
                      filterable
                      clearable
                      default-first-option
                      @change="handleCurrentChange(1)"
                      @focus="fetchAllPhysicians"
                      :loading="physiciansLoading"
                    >
                      <el-option
                        v-for="physician in physiciansData.data"
                        :key="physician"
                        :label="physician.name"
                        :value="physician._id"
                      ></el-option>
                    </el-select>
                    <span class="search-icon">
                      <img
                        src="@/assets/images/icons/search-icon-with-bg-red.svg"
                        alt="icon"
                        class="img-fluid"
                      />
                    </span>
                  </div>
                </li>
                <li v-else-if="searchBy == 'case_types'">
                  <div
                    class="form-element search-with-filter-input"
                    v-if="
                      getAuthenticatedUser.user_type == 'LAB_TECHNOLOGIST' &&
                      getAuthenticatedUser.allowed_case_types
                    "
                  >
                    <el-select
                      v-model="caseType"
                      placeholder="Select"
                      filterable
                      clearable
                      default-first-option
                      :reserve-keyword="false"
                      @change="handleCurrentChange(1)"
                    >
                      <el-option
                        v-for="caseType in getUserBasedCaseTypes(caseTypes)"
                        :key="caseType"
                        :label="caseType.label"
                        :value="caseType.value"
                      ></el-option>
                    </el-select>
                    <span class="search-icon">
                      <img
                        src="@/assets/images/icons/search-icon-with-bg-red.svg"
                        alt="icon"
                        class="img-fluid"
                      />
                    </span>
                  </div>
                  <div class="form-element search-with-filter-input" v-else>
                    <el-select
                      v-model="caseType"
                      placeholder="Select"
                      filterable
                      clearable
                      default-first-option
                      :reserve-keyword="false"
                      @change="handleCurrentChange(1)"
                    >
                      <el-option
                        v-for="caseType in getLabBasedCaseTypes(caseTypes)"
                        :key="caseType"
                        :label="caseType.label"
                        :value="caseType.value"
                      ></el-option>
                    </el-select>
                    <span class="search-icon">
                      <img
                        src="@/assets/images/icons/search-icon-with-bg-red.svg"
                        alt="icon"
                        class="img-fluid"
                      />
                    </span>
                  </div>
                </li>
                <li>
                  <el-button
                    class="btn button-with-icon"
                    @click="onReportsExport"
                  >
                    <span class="mr-8">
                      <img
                        src="@/assets/images/icons/export-icon-with-bg-blue.svg"
                        alt="icon"
                        class="inactive"
                      />
                      <img
                        src="@/assets/images/icons/export-icon-white.svg"
                        alt="icon"
                        class="active"
                      />
                    </span>
                    <span>Export</span>
                  </el-button>
                </li>
              </ul>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="container-default" style="padding-top: 0">
      <div class="primary-content-view-new" v-loading="loading">
        <div
          class="listings listings__table listings__all-cases"
          id="all-cases-listings"
        >
          <el-table
            :data="getAllSendOuts.data"
            height="calc(100vh - 200px)"
            :default-sort="{
              prop: orderBy,
              order: orderType == 'asc' ? 'ascending' : 'descending',
            }"
            style="width: 100%"
            @sort-change="onChangeSort"
          >
            <el-table-column
              fixed="left"
              prop="sendout_id"
              label="SENDOUT"
              min-width="150"
              sortable="custom"
            >
              <template #default="scope">
                <div class="name-accession">
                  <span id="accession-id" @click="onView(scope.row._id)">{{
                    scope.row.sendout_id
                  }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              fixed="left"
              prop="accession_id"
              label="ACCESSION ID"
              min-width="130"
              sortable="custom"
            >
              <template #default="scope">
                <template v-if="scope.row.case_id">
                  <div class="name-accession cursor">
                    <span
                      id="accession-id-blue"
                      @click="goToCaseView(scope.row.case_id)"
                      >{{ scope.row.accession_id }}</span
                    >
                  </div>
                </template>
              </template>
            </el-table-column>
            <el-table-column
              label="Case Type"
              min-width="125"
              prop="case_types"
              sortable="custom"
              fixed="left"
            >
              <template #default="scope">
                <div
                  class="case-type"
                  v-if="scope.row.case_types && scope.row.case_types.length > 1"
                >
                  <el-dropdown>
                    <span class="case-data-type">
                      {{ getCaseTypes(scope.row.case_types)[0] }}
                      <img
                        src="@/assets/images/case-type-arrow.svg"
                        alt="icon"
                      />
                    </span>

                    <template #dropdown>
                      <el-dropdown-menu class="case-type-dropdown">
                        <el-dropdown-item
                          class="cases-view"
                          v-for="(caseType, index) of getCaseTypes(
                            scope.row.case_types
                          )"
                          :key="index"
                          >{{ caseType }}</el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </div>
                <div v-else class="case-type">
                  <span class="case-data-type">{{
                    getCaseTypes(scope.row.case_types)[0]
                  }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="patient_info.first_name"
              label="PATIENT NAME"
              width="140"
              sortable="custom"
              fixed="left"
            >
              <template v-slot="scope">
                <el-button
                  placement="top"
                  class="title-on-hover"
                  :title="getPatientNameByInfo(scope.row.patient_info)"
                  @click="
                    copyTitle(getPatientNameByInfo(scope.row.patient_info))
                  "
                >
                  <span class="text-center ordering_facility-value">{{
                    getPatientNameByInfo(scope.row.patient_info)
                  }}</span>
                </el-button>
              </template>
            </el-table-column>
            <el-table-column
              label="Patient DOB"
              min-width="120"
              prop="date_of_birth"
            >
              <template #default="scope">
                <div v-if="scope.row.patient_info">
                  {{ formatDate(scope.row.patient_info.date_of_birth) }}
                </div>
              </template>
            </el-table-column>

            <el-table-column
              prop="hospitals"
              label="ORDERING FACILITIES"
              min-width="215"
            >
              <template #default="scope">
                <el-button
                  placement="top"
                  class="title-on-hover"
                  :title="scope.row.hospital ? scope.row.hospital.name : '--'"
                >
                  <span class="ordering_facility-value">{{
                    scope.row.hospital ? scope.row.hospital.name : "--"
                  }}</span>
                </el-button>
              </template>
            </el-table-column>
            <el-table-column prop="physician" label="physician" min-width="150">
              <template #default="scope">
                <el-button
                  placement="top"
                  class="title-on-hover"
                  :title="
                    scope.row.ordering_physician
                      ? scope.row.ordering_physician.name.includes('Dr')
                        ? scope.row.ordering_physician.name
                        : 'Dr ' + scope.row.ordering_physician.name
                      : '--'
                  "
                >
                  <span class="text-center ordering_facility-value">{{
                    scope.row.ordering_physician
                      ? scope.row.ordering_physician.name.includes("Dr")
                        ? scope.row.ordering_physician.name
                        : "Dr " + scope.row.ordering_physician.name
                      : "--"
                  }}</span>
                </el-button>
              </template>
            </el-table-column>
            <el-table-column
              label="CREATED ON"
              min-width="130"
              prop="created_at"
              sortable="custom"
            >
              <template #default="scope">
                <div v-if="scope.row.created_at">
                  <div class="d-flex">
                    <div class="text-center date pr-5">
                      {{
                        momentWithTimezone(scope.row.created_at, "MM-DD-yyyy")
                      }}
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="Received On"
              min-width="130"
              prop="received_date"
              sortable="custom"
            >
              <template #default="scope">
                <div v-if="scope.row.received_date">
                  <div class="d-flex">
                    <div class="date pr-5">
                      {{
                        momentWithTimezone(
                          scope.row.received_date,
                          "MM-DD-yyyy"
                        )
                      }}
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="Status"
              min-width="130"
              prop="status"
              sortable="custom"
            >
              <template v-slot="scope">
                <div class="column-status">
                  <span
                    class="el-dropdown-link"
                    v-if="scope.row.status && scope.row.status == 'COMPLETE'"
                  >
                    <el-tag :type="getStatusType(scope.row.status)"
                      >FINALIZED</el-tag
                    >
                  </span>
                  <span class="el-dropdown-link" v-else>
                    <el-tag :type="getStatusType(scope.row.status)">
                      {{ scope.row.status.split("_").join(" ") }}
                    </el-tag>
                  </span>
                </div>
              </template>
            </el-table-column>
            <!-- <el-table-column
              prop="ncf_order_status"
              label="NCF Order Status"
              width="140"
              v-if="checkLab"
            >
              <template v-slot="scope">
                <el-button
                  v-if="scope.row.ncf_order_status"
                  class="btn-success cursor-test"
                  >{{ scope.row.ncf_order_status }}</el-button
                >
              </template>
            </el-table-column> -->
            <!-- <el-table-column
              label="NCF Report Status"
              width="140"
              v-if="checkLab"
            >
              <template v-slot="scope">
                <el-button
                  type="text"
                  class="btn-active"
                  v-if="
                    scope.row.ncf_order_status == 'SENT' &&
                    scope.row.ncf_report_status == 'PENDING'
                  "
                  @click="syncReports(scope.row._id)"
                  >Sync</el-button
                >
                <el-button
                  type="text"
                  class="btn-processing"
                  v-if="
                    scope.row.ncf_order_status == 'SENT' &&
                    scope.row.ncf_report_status == 'ERROR'
                  "
                  @click="syncReports(scope.row._id)"
                  >Re-Sync</el-button
                >
                <span v-else>
                  <el-button
                    type="text"
                    class="btn-success cursor-test"
                    v-if="scope.row.ncf_report_status == 'SYNCED'"
                    >COMPLETED</el-button
                  >
                </span>
              </template>
            </el-table-column> -->
            <!-- <el-table-column
              label="NCF Report Message"
              width="150"
              v-if="checkLab"
            >
              <template v-slot="scope">
                <span>{{ scope.row.ncf_report_message }}</span>
              </template>
            </el-table-column> -->
            <el-table-column
              prop="action"
              label="Actions"
              width="150"
              fixed="right"
            >
              <template v-slot="scope">
                <ul class="listings__action-buttons">
                  <li @click="sendoutPrint(scope.row._id)">
                    <el-button class="view-button" title="Print Sendout">
                      <img
                        class="inactive"
                        src="@/assets/images/printer-icon-black.svg"
                        alt="icon"
                      />
                    </el-button>
                  </li>
                  <li @click="onView(scope.row._id)">
                    <el-button class="view-button" title="View Sendout">
                      <img
                        class="action-active"
                        src="@/assets/images/icons/actions/view-icon.svg"
                        alt="icon"
                      />
                      <img
                        class="action-inactive"
                        src="@/assets/images/icons/actions/view-inactive.svg"
                        alt="icon"
                      />
                    </el-button>
                  </li>
                  <li
                    v-if="
                      scope.row.status != 'ARCHIVED' &&
                      scope.row.status == 'COMPLETE' &&
                      checkNewReport(scope.row)
                    "
                  >
                    <el-button
                      title="Test Reports"
                      class="reports-button"
                      @click="onViewReports(scope.row)"
                    >
                      <span class="afterTestReportsIcon">
                        <img
                          src="@/assets/images/icons/actions/afterViewIcon.svg"
                          alt="icon"
                          style="width: 18px"
                        />
                      </span>
                    </el-button>
                  </li>
                  <li
                    v-if="
                      scope.row.status != 'ARCHIVED' &&
                      hasPermission('send_outs', 'create')
                    "
                  >
                    <el-button
                      class="delete-button"
                      @click="onDelete(scope.row._id)"
                      title="Delete Sendout"
                    >
                      <img
                        class="action-active"
                        src="@/assets/images/icons/actions/delete-active.svg"
                        alt="icon"
                      />
                      <img
                        class="action-inactive"
                        src="@/assets/images/icons/actions/delete-deactive.svg"
                        alt="icon"
                      />
                    </el-button>
                  </li>
                  <!-- <li
                    v-if="
                      scope.row.is_ncf_order &&
                      checkLab &&
                      scope.row.ncf_order_status != 'SENT'
                    "
                  >
                    <el-button
                      class="view-button"
                      title="Send Order"
                      @click="sendNcfOrder(scope.row._id)"
                    >
                      <img
                        class="inactive"
                        src="@/assets/images/send-order.svg"
                        alt="icon"
                      />
                    </el-button>
                  </li> -->
                </ul>
              </template>
            </el-table-column>
          </el-table>
          <div class="listings listings__pagination">
            <el-pagination
              v-model:currentPage="page"
              v-model:page-size="pageSize"
              :page-sizes="[25, 50, 75, 100]"
              layout="prev, pager, next, jumper, sizes, total"
              :total="getAllSendOuts.total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <div class="default-modal">
      <div v-if="showAddNewSendOutDialog" class="export-send-outs">
        <el-dialog
          top="10%"
          width="30%"
          v-model="showAddNewSendOutDialog"
          title="New Sendout"
        >
          <div class="dialog-header">
            <span class="header-part">New Sendout</span>
            <el-button
              size="medium"
              class="el-white"
              type="info"
              plain
              @click="showAddNewSendOutDialog = false"
            >
              <img src="@/assets/images/icons/cases/close-btn.svg" alt="icon" />
            </el-button>
          </div>
          <el-row :gutter="20" type="flex" class="popup-date pb-5">
            <el-col :span="24">
              <div class="popup-el-select">
                <label for="text">Accession #</label>

                <el-select
                  v-model="newSendoutCaseInfo"
                  filterable
                  remote
                  :remote-method="querySpuAttrNames"
                  default-first-option
                  :loading="searchCaseLoading"
                  clearable
                >
                  <el-option
                    v-for="(caseData, index) of allCases"
                    :key="index"
                    value-key="_id"
                    :label="caseData.accession_id"
                    :value="caseData"
                  ></el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="24">
              <div class="dialog-footer">
                <el-button class="cancel-button" @click="closeNewSendoutDialog"
                  >Cancel</el-button
                >
                <el-button
                  class="blue-save-button"
                  :disabled="!newSendoutCaseInfo"
                  @click="goToNewSendOut"
                >
                  Add
                  <i class="el-icon-plus"></i>
                </el-button>
              </div>
            </el-col>
          </el-row>
        </el-dialog>
      </div>
    </div>
    <SendoutsExport
      v-if="exportSendoutsDialogVisisble"
      v-on:close="exportSendoutsDialogVisisble = false"
      :search_string="search_string"
      :search_hospital="hospital"
      :search_physician="physician"
      :search_dob="date_of_birth"
      :search_caseType="caseType"
      :search_status="selectedStatuses"
      :pregnancy="pregnant"
    >
    </SendoutsExport>
  </section>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { mapGetters } from "vuex";
import moment from "moment";
import caseConstants from "@/config/constants/caseConstants";
import FilterHelper from "@/mixins/FilterHelper";
import UserPermissionsHelper from "@/mixins/UserPermissionsHelper";
import exportExcelHelper from "@/mixins/exportExcelHelper";
import {
  sendOutSearchOptions,
  pregnencyOptions,
} from "@/config/constants/searchConstants";
const SendoutsExport = defineAsyncComponent(() =>
  import("@/components/sendOuts/ExportSendOuts")
);
import caseDataHelper from "@/mixins/caseDataHelper";
import {
  successNotification,
  errorNotification,
} from "@/helpers/notifications";
export default {
  name: "hospital",
  props: {
    selectedStatuses: {
      default: [],
    },
  },
  components: { SendoutsExport },
  mixins: [
    FilterHelper,
    UserPermissionsHelper,
    exportExcelHelper,
    caseDataHelper,
  ],
  data() {
    return {
      disabledDate: (time) => {
        return time.getTime() > Date.now();
      },
      scrollPosition: null,
      sendOutExportDialog: false,
      // searchByKey: ["PatientName", "accession_id", "EMR"],
      searchOptions: sendOutSearchOptions,
      pregnencyOptions: pregnencyOptions,
      search_string: "",
      hospital: "",
      date_of_birth: "",
      physician: "",
      searchBy: "",
      caseType: "",
      page: 1,
      pageSize: 25,
      caseTypeMap: caseConstants.CASE_TYPE_MAP,
      caseTypes: caseConstants.CASE_TYPES_DATA,
      loading: false,
      orderBy: "",
      orderType: "",
      showAddNewSendOutDialog: false,
      newSendoutCaseInfo: "",
      searchCaseLoading: false,
      exportSendoutsDialogVisisble: false,
      allCases: [],
      hospitalsData: {},
      physiciansData: {},
      physiciansLoading: false,
      hospitalsLoading: false,
      pregnant: "",
    };
  },
  computed: {
    checkCaseStatus() {
      return (caseData) => {
        if (caseData.status === "COMPLETE") {
          return true;
        } else {
          return false;
        }
      };
    },
    checkNewReport() {
      return (caseData) => {
        if (caseData && caseData.test_reports && caseData.test_reports.length) {
          return true;
        } else {
          return false;
        }
      };
    },

    getStatusType() {
      return (statusVal) => {
        const status = statusVal.trim();
        if (status == "ACTIVE") {
          return "default";
        } else if (status == "COMPLETE") {
          return "success";
        } else if (status == "TEST_REPORTS") {
          return "warning";
        } else if (status == "TEST_RESULTS") {
          return "info";
        } else if (status == "ARCHIVED") {
          return "danger";
        } else {
          return "";
        }
      };
    },
    ...mapGetters("sendOuts", [
      "getAllSendOuts",
      "getSendOutDeleteStatus",
      "getSendOutDeleteError",
      "getReportSyncStatus",
      "getOrderSentStatus",
    ]),
    ...mapGetters("cases", [
      "getAllCases",
      "getTestReportsDownloadLog",
      "getTestReportsViewLog",
    ]),
    ...mapGetters("hospitals", ["getAllHospitals"]),
    ...mapGetters("physicians", ["getAllPhysicians"]),
    ...mapGetters("auth", ["getAuthenticatedUser"]),
    ...mapGetters("profile", ["getProfileData"]),
    isSearchByInput() {
      return this.searchByKey.indexOf(this.searchBy) != -1 ? true : false;
    },
    getCaseTypes() {
      return (caseTypes) => {
        return (caseTypes || []).map((e) => this.caseTypeMap[e]);
      };
    },
    checkLab() {
      if (
        this.getProfileData &&
        this.getProfileData.lab &&
        this.getProfileData.lab.lab_code === "DLW"
      ) {
        return false;
      }
      return true;
    },
  },
  mounted() {
    this.setQueryParamsData();
    this.fetchAllSendOuts();
  },
  methods: {
    onViewReports(report) {
      let testReportsData = report.test_reports;
      for (let i = 0; i < testReportsData.length; i++) {
        if (
          (testReportsData[i].is_manually ||
            testReportsData[i].additional_report_merged ||
            testReportsData[i].additional_report_already_merged ||
            testReportsData[i].download_ready) &&
          testReportsData[i].mark_for_physician
        ) {
          let routerData = this.$router.resolve({
            name: "supplementaryReportView",
            params: {
              report_id: testReportsData[i]._id,
              case_id: report.case_id,
            },
          });
          window.open(routerData.href, "_blank");
        } else {
          if (testReportsData[i].mark_for_physician) {
            let routerData = this.$router.resolve({
              name: "ResultFileTestReports",
              params: {
                report_id: testReportsData[i]._id,
                case_id: report.case_id,
              },
            });
            window.open(routerData.href, "_blank");
          }
        }
        this.testReportsViewLog(report, testReportsData[i]);
        this.testReportsDownloadLog(report, testReportsData[i]);
      }
    },
    async testReportsDownloadLog(report, testReports) {
      let params = {
        case_id: report.case_id,
        report_id: testReports._id,
      };
      await this.$store.dispatch("cases/testReportsDownloadLog", params);
    },
    async testReportsViewLog(report, testReports) {
      let params = {
        case_id: report.case_id,
        report_id: testReports._id,
      };
      await this.$store.dispatch("cases/testReportsViewLog", params);
    },
    sendoutPrint(sendoutId) {
      let urlData = this.$router.resolve({
        name: "SendOutPrint",
        params: { sendout_id: sendoutId },
      });
      window.open(urlData.href, "_blank");
    },
    async updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    goToCaseView(case_id) {
      this.$router.push({
        name: "CaseView",
        params: { case_id: case_id },
        query: { ...this.$route.query, redirectRoute: this.$route.name },
      });
    },
    async syncReports(id) {
      try {
        this.loading = true;
        await this.$store.dispatch("sendOuts/syncNfcReports", {
          sendout_id: id,
        });
        this.loading = false;
        if (this.getReportSyncStatus) {
          successNotification("Reports Sync successfully");
          this.fetchAllSendOuts();
        } else {
          errorNotification("Report not syncing");
        }
      } catch (err) {
        console.log(err);
      }
    },
    async sendNcfOrder(id) {
      try {
        this.loading = true;
        await this.$store.dispatch("sendOuts/sendNcfOrder", {
          sendout_id: id,
        });
        this.loading = false;
        if (this.getOrderSentStatus) {
          successNotification("Order sent successfully");
          this.fetchAllSendOuts(this.page);
        } else {
          errorNotification("Error while sending order");
        }
      } catch (err) {
        console.log(err);
      }
    },

    async querySpuAttrNames(query) {
      try {
        if (query.length >= 3) {
          this.searchCaseLoading = true;
          let params = { search_string: query, get_all: true };
          await this.$store.dispatch("cases/fetchAllCases", params);
          this.allCases = this.getAllCases.data;
          this.searchCaseLoading = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    closeNewSendoutDialog() {
      this.showAddNewSendOutDialog = false;
    },
    onAddNewSendout() {
      this.showAddNewSendOutDialog = true;
      this.newSendoutCaseInfo = "";
    },
    async goToNewSendOut() {
      this.showAddNewSendOutDialog = false;
      this.onSendOut(this.newSendoutCaseInfo);
    },
    onSendOut(caseInfo) {
      let urlData = this.$router.resolve({
        name: "SendOutsView",
        query: {
          case_id: caseInfo._id,
          accession_id: caseInfo.accession_id,
          redirectRoute: this.$route.name,
          ...this.$route.query,
        },
      });

      window.location.href = urlData.href;
    },
    async onReportsExport() {
      this.loading = true;

      this.exportSendoutsDialogVisisble = true;
      this.loading = false;
    },
    onView(caseId) {
      this.$router.push({
        name: "SendoutsInformation",
        params: { sendout_id: caseId },
        query: { ...this.$route.query, redirectRoute: this.$route.name },
      });
    },
    async onDelete(id) {
      this.$confirm("Are you sure to delete send-out?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.deleteSendOut(id);
      });
    },
    async deleteSendOut(id) {
      try {
        await this.$store.dispatch("sendOuts/deleteSendOut", {
          send_out_id: id,
        });
        if (this.getSendOutDeleteStatus) {
          successNotification("Send-out deleted successfully");

          this.loading = true;

          await this.fetchAllSendOuts(this.page);

          this.loading = false;
        } else {
          errorNotification(
            this.getSendOutDeleteError || "Error while deleting Send-Out"
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
    clearSearchBy() {
      if (this.$route.query.selectedSearchBy == "Pregnancy") {
        this.fetchAllSendOuts(1);
      }
    },
    clearInputSearch() {
      if (
        this.hospital ||
        this.physician ||
        this.date_of_birth ||
        this.caseType
      ) {
        this.hospital = "";
        this.physician = "";
        this.date_of_birth = "";
        this.caseType = "";
        this.isSearchByInput = "";
        this.page = 1;
        this.fetchAllSendOuts(1);
      }
      if (this.searchBy == "Pregnancy") {
        this.fetchAllSendOuts(1);
      }
    },
    async fetchAllPhysicians() {
      try {
        this.physiciansLoading = true;
        await this.$store.dispatch("physicians/fetchAllPhysicians", {
          get_all: true,
        });
        this.physiciansData = JSON.parse(JSON.stringify(this.getAllPhysicians));
        this.physiciansLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchAllHospitals() {
      try {
        this.hospitalsLoading = true;
        await this.$store.dispatch("hospitals/fetchAllHospitals", {
          get_all: true,
        });
        this.hospitalsData = JSON.parse(JSON.stringify(this.getAllHospitals));
        this.hospitalsLoading = false;
      } catch (error) {
        console.log(error);
      }
    },

    setQueryParamsData() {
      this.page = this.$route.query.selectedPage
        ? parseInt(this.$route.query.selectedPage)
        : 1;
      this.pageSize = this.$route.query.selectedPageSize
        ? parseInt(this.$route.query.selectedPageSize)
        : 25;
      if (this.$route.query.selectedString) {
        this.search_string = this.$route.query.selectedString;
      }
      if (this.$route.query.selectedCase) {
        this.caseType = this.$route.query.selectedCase;
      }
      if (this.$route.query.selectedSearchBy) {
        this.searchBy = this.$route.query.selectedSearchBy;
      }
      if (this.$route.query.selectedPhysician) {
        this.fetchAllPhysicians();
        this.physician = this.$route.query.selectedPhysician;
      }
      if (this.$route.query.selectedHospital) {
        this.fetchAllHospitals();
        this.hospital = this.$route.query.selectedHospital;
      }
      if (this.$route.query.selectedDateOfBirth) {
        this.date_of_birth = this.$route.query.selectedDateOfBirth;
      }
      if (this.$route.query.selectedOrderBy) {
        this.orderBy = this.$route.query.selectedOrderBy;
      }
      if (this.$route.query.selectedOrderType) {
        this.orderType = this.$route.query.selectedOrderType;
      }
    },
    getAutoCompleteOrders() {
      setTimeout(() => {
        this.page = 1;
        this.fetchAllSendOuts();
      }, 600);
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.page = 1;

      this.fetchAllSendOuts();
    },
    handleCurrentChange(page) {
      this.page = page;

      this.fetchAllSendOuts();
    },
    onChangeSort({ order, prop }) {
      if (order && prop) {
        this.orderBy = prop;
        this.orderType = order == "ascending" ? "asc" : "desc";
      } else {
        this.orderBy = "";
        this.orderType = "";
      }
      this.fetchAllSendOuts();
    },
    prepareParams() {
      this.pregnant = "";
      let params = {
        page: this.page,
        limit: this.pageSize,
      };
      if (this.search_string) {
        params.search_string = this.search_string;
      }
      if (this.date_of_birth) {
        const date_of_birth = moment(this.date_of_birth).format("YYYY-MM-DD");

        params.date_of_birth = date_of_birth;
      }
      if (this.hospital) {
        params.hospital = this.hospital;
      }
      if (this.caseType) {
        params.case_type = this.caseType;
      }
      if (this.physician) {
        params.ordering_physician = this.physician;
      }
      if (this.searchBy == "Pregnancy") {
        this.pregnant = true;
        params.pregnant = true;
      }
      if (this.orderBy && this.orderType) {
        params.order_by = this.orderBy;
        params.order_type = this.orderType;
      }
      params.status = this.selectedStatuses;
      return params;
    },
    updateQueryParams(params) {
      let selectedParams = {
        selectedPage: params.page,
        selectedCase: params.case_type,
        selectedHospital: params.hospital,
        selectedDateOfBirth: params.date_of_birth,
        selectedPhysician: params.ordering_physician,
        selectedString: params.search_string,
        selectedPageSize: params.limit,
        selectedOrderBy: params.order_by,
        selectedOrderType: params.order_type,
        selectedSearchBy: this.searchBy || undefined,
      };
      this.updateQueryParam(this.$route.name, selectedParams);
    },
    async fetchAllSendOuts() {
      try {
        this.loading = true;
        let params = this.prepareParams();
        await this.$store.dispatch("sendOuts/getSendOuts", params);
        this.updateQueryParams(params);
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@mixin button-css {
  min-width: 100px;
  height: auto;
  min-height: 20px;
  padding: 6px 5px;
  color: #4bb543;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 1;
  letter-spacing: 0 !important;
  // border: none;
  text-align: center;
  // color: #fff;
  // background-color: #3fc380;
  border: 0.6px solid #4bb543;
  border-radius: 4px !important;
  &:hover,
  &:active,
  &:focus {
    color: #4bb543;
    // color: #fff;
    // background-color: #3fc380 !important;
    border: 0.6px solid #4bb543;
  }
}
.btn-success {
  @include button-css;
}
.btn-danger {
  @include button-css;
  color: #eb5757 !important;
  border: 0.6px solid #eb5757 !important;
  &:hover,
  &:active &:focus {
    color: #eb5757;
    border: 0.6px solid #eb5757;
  }
}

.btn-active {
  @include button-css;
  color: #5162fd !important;
  border: 0.6px solid #5162fd !important;
  &:hover,
  &:active &:focus {
    color: #5162fd;
    border: 0.6px solid #5162fd;
  }
}
.btn-processing {
  @include button-css;
  color: #f28b0c !important;
  border: 0.6px solid #f28b0c !important;
  &:hover,
  &:active,
  &:focus {
    color: #f28b0c;
    border: 0.6px solid #f28b0c;
  }
}
#all-sendOuts-list {
  .stickyNavbar {
    padding-bottom: 5px !important;
    .container-default {
      padding-top: 0;
      padding-bottom: 5px;
    }
  }
}
</style>
